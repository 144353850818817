<template>
  <div class="section-wrapper">
    <b-row>
      <b-col lg="12" sm="12">
        <body-card>
          <template v-slot:body>
            <b-row>
            <b-overlay :show="loading">
              <b-col lg="12" sm="12">
                <template>
                  <div class="text-right">
                    <b-button variant="primary" @click="pdfExport" class="btn btn-sm mb-2">
                      <i class="ri-printer-line"></i> {{ $t('globalTrans.print') }}
                    </b-button>
                  </div>
                  <TeaGardenReportHead :baseUrl="teaGardenServiceBaseUrl" :uri="'/configuration/report-heading/detail'" :orgId="5" :loadingState="false">
                    {{ $t('teaGardenService.bangladesh_tea_board') }}
                  </TeaGardenReportHead>
                  <div class="row mt-3">
                    <div class="col-6">{{$t('teaGardenService.memorial_no')}}: {{ EngBangNum(item.memo_no) }}</div>
                    <div class="col-6 text-right">{{$t('teaGardenService.date')}}: {{ item.date | dateFormat }}</div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-4 offset-5">
                      <b style="border-bottom:1px solid black;"> {{ ($i18n.locale === 'bn') ? item.title_bn : item.title_en }} </b>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12">
                      <div v-if="$i18n.locale === 'bn'" v-html="item.top_text_bn">
                      </div>
                      <div v-else v-html="item.top_text_en">
                      </div>
                      <table class="table table-bordered table-sm">
                        <thead class="text-center">
                          <tr>
                            <th>{{ $t('globalTrans.sl_no') }}</th>
                            <th>{{ $t('national_award.category_types') }}</th>
                            <th>{{ $t('national_award.award') }} </th>
                            <th>{{ $t('globalTrans.number') }} </th>
                        </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(category, index) in categories" :key="index">
                            <td class="text-center">{{$n(index+1)}}</td>
                            <td class="text-left">{{getCategoryName(category.value)}}</td>
                            <td class="text-left" v-if="index == 0" :rowspan="categories.length" v-html="$i18n.locale === 'bn' ? item.award_bn :  item.award_en"></td>
                            <td class="text-left" v-if="index == 0" :rowspan="categories.length">{{ $i18n.locale === 'bn' ? item.number_of_award_bn : item.number_of_award_en}}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div>
                       <div v-if="$i18n.locale === 'bn'" v-html="item.bottom_text_bn">
                        </div>
                        <div v-else v-html="item.bottom_text_en">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-5 text-center">
                    <div class="col-5 offset-7">
                      <p v-if="signatory.show_signature && signatory.signature"><img style="width:60px" :src="authServiceBaseUrl + 'download-attachment?file=app/public/'+ signatory.signature" alt=""></p>
                      <p>{{ $i18n.locale === 'bn' ? signatory.text_bn : signatory.text_en }}</p>
                      <p>{{ $i18n.locale === 'bn' ? signatory.designation_bn : signatory.designation_en }}</p>
                      <p>{{ $i18n.locale === 'bn' ? signatory.office_bn : signatory.office_en }}</p>
                      <p>{{$t('teaGardenService.phone')}}: {{ $n(signatory.mobile, {useGrouping: false}) }}</p>
                      <p>{{$t('teaGardenService.email')}}: {{ signatory.email }}</p>
                    </div>
                  </div>
                </template>
              </b-col>
             </b-overlay>
            </b-row>
          </template>
        </body-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import TeaGardenReportHead from '@/components/custom/TeaGardenReportHead'
import RestApi, { teaGardenServiceBaseUrl, authServiceBaseUrl } from '@/config/api_config'
import { awardCircularShowApi } from '../../../api/routes'
export default {
  name: 'Details',
  props: ['id', 'categories', 'signatory'],
  components: {
    TeaGardenReportHead
  },
  data () {
    return {
      teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
      authServiceBaseUrl: authServiceBaseUrl,
      valid: null,
      loading: false,
      item: {},
      errors: [],
      detailsData: [],
      detailsItemId: ''
    }
  },
  created () {
    const tmp = this.getCircularDetails()
    this.item = tmp
    this.categories = this.categories.filter(cat => this.item.category_ids.includes(cat.value))
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getCircularDetails () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getCategoryName (catId) {
      const cateObj = this.categories.find(item => item.value === catId)
      if (cateObj !== undefined) {
          if (this.$i18n.locale === 'bn') {
              return cateObj.text_bn
          } else {
              return cateObj.text_en
          }
      }
    },

    convertToBanglaDate (dateString) {
      const timestamp = new Date(dateString).getTime()

      const dayNumber = new Date(timestamp).getDate()
      const monthNumber = new Date(timestamp).getMonth() + 1
      const yearNumber = new Date(timestamp).getFullYear()

      // Bangla translations for months
      const monthNames = [
          'জানুয়ারি', 'ফেব্রুয়ারি', 'মার্চ', 'এপ্রিল', 'মে', 'জুন',
          'জুলাই', 'আগস্ট', 'সেপ্টেম্বর', 'অক্টোবর', 'নভেম্বর', 'ডিসেম্বর'
      ]
      const monthNamesEn = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
      ]

      const month = this.$i18n.locale === 'bn' ? monthNames[monthNumber - 1] : monthNamesEn[monthNumber - 1]

      // Convert day number and year to Bangla
      const banglaDayNumber = this.$n(dayNumber)
      const year = this.$n(yearNumber, { useGrouping: false })

      const banglaDate = `${banglaDayNumber} ${month} ${year}`

      return banglaDate
    },

    async pdfExport () {
      this.loading = true
      const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 5, app_id: this.id })
      const service = this.$store.state.TeaGardenService.commonObj.masterServiceList.find(item => item.value === 19)
      if (service !== undefined) {
        if (service.office_type_id) {
          params.office_type_id = service.office_type_id
        }
        if (service.office_id) {
          params.office_id = service.office_id
        }
      }
      const result = await RestApi.getPdfData(teaGardenServiceBaseUrl, awardCircularShowApi + '/' + this.id, params)
      var blob = new Blob([result], {
        type: 'application/pdf'
      })
      this.loading = false
      var url = window.URL.createObjectURL(blob)
      window.open(url).print()
    }
  }
}
</script>
