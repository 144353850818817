<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <pre>
          </pre>
          <!-- year -->
          <b-col xl="12" lg="12" sm="12">
            <ValidationProvider name="Category" vid="category_ids" rules="required|min_value:1" v-slot="{ errors }">
              <b-form-group
                label-for="category_ids"
              >
              <template v-slot:label>
                {{$t('national_award.category')}} <span class="text-danger">*</span>
              </template>
              <div>
                  <b-form-checkbox-group
                    stacked
                    class="text-dark"
                    v-model="data.category_ids"
                    :options="CategoryList"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-checkbox-group>
              </div>
              <div class="invalid-feedback d-block">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Year" vid="year" rules="required|min:4|numeric">
              <b-form-group
                label-for="year"
                slot-scope="{ valid, errors }"
              >
              <template v-slot:label>
                {{$t('globalTrans.year')}} <span class="text-danger">*</span>
              </template>
              <b-form-input
                  id="year"
                  v-model="data.year"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- title en -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Title (En)" vid="title_en" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="title_en">
                <template v-slot:label>
                  {{ $t('teaGardenService.title') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="title_en"
                  v-model="data.title_en"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- title bn -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Title (Bn)" vid="title_bn" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="title_bn">
                <template v-slot:label>
                  {{ $t('teaGardenService.title') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="title_bn"
                  v-model="data.title_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- published_date -->
          <b-col sm="6">
            <ValidationProvider name="Published Date" vid="date" rules="required">
              <b-form-group
                class="row"
                label-for="date"
                slot-scope="{ valid, errors }">
                <template v-slot:label>
                  {{ $t('teaGardenService.published_date') }}  <span class="text-danger">*</span>
                </template>
                <date-picker
                  id="date"
                  v-model="data.date"
                  class="form-control"
                  :placeholder="$t('globalTrans.select_date')"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :class="errors[0] ? 'is-invalid' : ''"
                  :config="{ static: true }"
                >
                </date-picker>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- deadline -->
          <b-col sm="6">
            <ValidationProvider name="Deadline" vid="expire_date" rules="required">
              <b-form-group
                class="row"
                label-for="expire_date"
                slot-scope="{ valid, errors }">
                <template v-slot:label>
                  {{ $t('teaGardenService.deadline') }}  <span class="text-danger">*</span>
                </template>
                <date-picker
                  id="expire_date"
                  v-model="data.expire_date"
                  class="form-control"
                  :placeholder="$t('globalTrans.select_date')"
                  :state="errors[0] ? false : (valid ? true : null)"
                  :class="errors[0] ? 'is-invalid' : ''"
                  :config="{ static: true }"
                >
                </date-picker>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- registration_no -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Memo No" vid="memo_no" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="memo_no">
                <template v-slot:label>
                  {{ $t('teaGardenService.memorial_no') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="memo_no"
                  v-model="data.memo_no"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
            <!-- title en -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Number Of Award (En)" vid="number_of_award_en" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="number_of_award_en">
                <template v-slot:label>
                  {{ $t('national_award.number_of_award') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="number_of_award_en"
                  v-model="data.number_of_award_en"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- title bn -->
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <ValidationProvider name="Title (Bn)" vid="number_of_award_bn" rules="required">
              <b-form-group
                slot-scope="{ valid, errors }"
                label-for="number_of_award_bn">
                <template v-slot:label>
                 {{ $t('national_award.number_of_award') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="number_of_award_bn"
                  v-model="data.number_of_award_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- top text en -->
          <b-col sm="12">
            <ValidationProvider name="Top Description (En)" vid="top_text_en">
              <b-form-group
                label-for="top_text_en">
                <template v-slot:label>
                  {{ $t('national_award.top_text') }} {{ $t('globalTrans.en') }}
                </template>
                <vue-editor
                  id="top_text_en"
                  v-model="data.top_text_en"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></vue-editor>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- top description en -->
          <b-col sm="12">
            <ValidationProvider name="Top Description (En)" vid="top_text_bn">
              <b-form-group
                label-for="top_text_bn">
                <template v-slot:label>
                  {{ $t('national_award.top_text') }} {{ $t('globalTrans.bn') }}
                </template>
                <vue-editor
                  id="top_text_bn"
                  v-model="data.top_text_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></vue-editor>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- bottom description en -->
          <b-col sm="12">
            <ValidationProvider name="Top Description (En)" vid="bottom_text_en">
              <b-form-group
                label-for="bottom_text_en">
                <template v-slot:label>
                  {{ $t('national_award.bottom_text') }} {{ $t('globalTrans.en') }}
                </template>
                <vue-editor
                  id="bottom_text_en"
                  v-model="data.bottom_text_en"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></vue-editor>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- bottom description bn -->
          <b-col sm="12">
            <ValidationProvider name="Bottom Description (Bn)" vid="bottom_text_bn">
              <b-form-group
                label-for="bottom_text_bn">
                <template v-slot:label>
                  {{ $t('national_award.bottom_text') }}  {{ $t('globalTrans.bn') }}
                </template>
                <vue-editor
                  id="bottom_text_bn"
                  v-model="data.bottom_text_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></vue-editor>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- description en -->
          <b-col sm="12">
            <ValidationProvider name="Award (En)" vid="award_en">
              <b-form-group
                label-for="award_en">
                <template v-slot:label>
                  {{ $t('national_award.award') }} {{ $t('globalTrans.en') }}
                </template>
                <vue-editor
                  id="award_en"
                  v-model="data.award_en"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></vue-editor>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <!-- description bn -->
          <b-col sm="12">
            <ValidationProvider name="Award (Bn)" vid="award_bn">
              <b-form-group
                label-for="award_bn">
                <template v-slot:label>
                  {{ $t('national_award.award') }}  {{ $t('globalTrans.bn') }}
                </template>
                <vue-editor
                  id="award_bn"
                  v-model="data.award_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></vue-editor>
                <div class="invalid-feedback d-block">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
import { awardCircularStoreApi, awardCircularUpdateApi } from '../../../api/routes'
import { VueEditor } from 'vue2-editor'
export default {
  name: 'Form',
  props: ['id', 'categories'],
  components: { VueEditor },
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      data: {
        category_ids: [],
        year: '',
        bottom_text_bn: '',
        bottom_text_en: '',
        top_text_bn: '',
        top_text_en: '',
        expire_date: ''
      }
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getserviceWiseInstruction()
      this.data = tmp
      this.data.category_ids = JSON.parse(tmp.category_ids)
    } else {
      this.data.category_ids = this.categories.map(category => category.value)
    }
    // this.CategoryList = this.categories
  },
  computed: {
    CategoryList () {
      return this.categories.map(el => {
        return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
      })
    },
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    fiscalYearList: function () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }
      if (this.id) {
        result = await RestApi.putData(teaGardenServiceBaseUrl, `${awardCircularUpdateApi}/${this.id}`, this.data)
      } else {
        result = await RestApi.postData(teaGardenServiceBaseUrl, awardCircularStoreApi, this.data)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('TeaGardenService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: this.$t('globalTrans.success'),
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getserviceWiseInstruction () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
